import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Paper } from "@material-ui/core";

const SimplePagination = ({
  pageNum = 0,
  pagesTotal = 0,
  paginationCallback,
}) => {
  const [pageLocalNum, setPageLocalNum] = useState(pageNum);

  const handlePreviousPageClick = () => {
    if (pageNum - 1 <= 0) {
      paginationCallback(pageNum);
      setPageLocalNum(pageNum);
    } else {
      paginationCallback(pageNum - 1);
      setPageLocalNum(pageNum - 1);
    }
  };

  const handleNextPageClick = () => {
    if (pageNum + 1 > pagesTotal) {
      paginationCallback(pageNum);
      setPageLocalNum(pageNum);
    } else {
      paginationCallback(pageNum + 1);
      setPageLocalNum(pageNum + 1);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.value > 0 && e.target.value <= pagesTotal)
      setPageLocalNum(e.target.value);
  };
  const handleCallbackChange = (e) => {
    paginationCallback(pageLocalNum);
  };

  return pagesTotal > 1 ? (
    <Paper style={customStyling.paginationContainer}>
      <div align="left">
        <Button
          startIcon={<ChevronLeftIcon />}
          size="small"
          variant="contained"
          color="primary"
          onClick={handlePreviousPageClick}
          disabled={pageNum - 1 <= 0 ? true : false}
        >
          Préc
        </Button>
      </div>

      <div align="center">
        <span style={customStyling.paginationState}>
          <input
            type="number"
            value={pageLocalNum}
            onChange={handleInputChange}
            onBlur={handleCallbackChange}
            style={customStyling.paginationInput}
          />{" "}
          / {pagesTotal}
        </span>
      </div>

      <div align="right">
        <Button
          endIcon={<ChevronRightIcon />}
          size="small"
          variant="contained"
          color="primary"
          onClick={handleNextPageClick}
          disabled={pageNum + 1 > pagesTotal ? true : false}
        >
          Suiv
        </Button>
      </div>
    </Paper>
  ) : (
    ""
  );
};

export default SimplePagination;

const customStyling = {
  paginationContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    padding: "10px",
    margin: "20px 0",
    borderRadius: "4px",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    alignItems: "center",
  },
  paginationState: {
    padding: "5px 15px",
    borderRadius: "4px",
  },
  paginationInput: {
    width: "80px",
    background: "transparent",
    border: "solid 1px",
    borderRadius: "4px",
    color: "inherit",
    padding: "4px",
  },
};
